import * as React from 'react'

// COMPONENTS
import Layout from 'layouts/main'
import ProfileForm from 'components/profile/form'
import SecuredRoute from 'hoc/SecuredRoute'

const ProfilePage = () => (
  <SecuredRoute>
    <Layout title='Profile'>
      <ProfileForm />
    </Layout>
  </SecuredRoute>
)

export default ProfilePage
