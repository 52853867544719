import * as React from 'react'
import { useState } from 'react'
import Loading from '../components/shared/loading'

const securedDomains = ['app.buildup.school', 'localhost']

const SecuredRoute = ({ children }) => {

  const [isSecured, setIsSecured] = useState(false)

  React.useEffect(() => {

    if (securedDomains.includes(window.location.hostname)) {
      setIsSecured(true)
      return
    }

    window.location.href = `https://${securedDomains[0]}/login?token=${window.localStorage.getItem('token')}&next=${window.location.pathname}${window.location.search}`

  }, [])


    if (!isSecured) {
      return <>
        <Loading />
      </>
    }

  return <>
    {children}
  </>
}

export default SecuredRoute
